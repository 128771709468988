export const handleScroll = (e, container) => {
  e.preventDefault();
  if (e.deltaY !== 0) {
    container.scrollLeft += e.deltaY;
  }
};

export const handleDragScroll = (container) => {
  let isDragging = false;
  let startX;
  let scrollLeft;

  const onMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - startX) * 2;
    container.scrollLeft = scrollLeft - walk;
  };

  const onMouseUpOrLeave = () => {
    isDragging = false;
  };

  container.addEventListener("mousedown", onMouseDown);
  container.addEventListener("mousemove", onMouseMove);
  container.addEventListener("mouseup", onMouseUpOrLeave);
  container.addEventListener("mouseleave", onMouseUpOrLeave);

  return () => {
    container.removeEventListener("mousedown", onMouseDown);
    container.removeEventListener("mousemove", onMouseMove);
    container.removeEventListener("mouseup", onMouseUpOrLeave);
    container.removeEventListener("mouseleave", onMouseUpOrLeave);
  };
};
